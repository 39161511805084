import React, { useState, type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import './admin-panel-sidebar.css'
import Modal from '../modal/Modal'
import AdminButton from '../adminButton/AdminButton'
import orderService from '../../../services/orderService'
import { ReactComponent as FolderIcon } from '../../../assets/folder_icon.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/calendar-icon.svg'

const AdminPanelSidebar = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('00:00')
  const [endTime, setEndTime] = useState<string>('23:30')

  // Generate time options (30 min intervals)
  const generateTimeOptions = (): string[] => {
    const times: string[] = []
    for (let hour = 0; hour < 24; hour++) {
      for (const minute of ['00', '30']) {
        times.push(`${hour.toString().padStart(2, '0')}:${minute}`)
      }
    }
    return times
  }

  const timeOptions = generateTimeOptions()

  const handleStartDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setStartDate(e.target.value)
  }

  const handleEndDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setEndDate(e.target.value)
  }

  const handleExport = async (): Promise<void> => {
    if (!startDate || !endDate) {
      alert('Veuillez sélectionner une date de début et une date de fin.')
      return
    }

    const searchTimestamp = `?from=${startDate}T${startTime}:00Z&to=${endDate}T${endTime}:00Z`

    try {
      await orderService.getArchiveOrders(searchTimestamp)
    } catch (error) {
      console.error('Failed to export data:', error)
    }
  }
  return (
    <>
      <div className='sidebar-container'>
        <div className='logo font__title--h3'>ADMIN PANEL BCA</div>
        <Link
          className='sidebar-item font__body--bold-large'
          to='/admin/audiences'
        >
          Gestion des Audiences
        </Link>
        <Link
          className='sidebar-item font__body--bold-large'
          to='/admin/products'
        >
          Gestion des Produits
        </Link>
        <Link
          className='sidebar-item font__body--bold-large'
          to='/admin/packages'
        >
          Gestion des Packages
        </Link>
        <Link
          className='sidebar-item font__body--bold-large'
          to='/admin/images'
        >
          Gestion des Images
        </Link>
        <Link
          className='sidebar-item font__body--bold-large'
          to='/admin/pre-orders'
        >
          Gestion des Pre-Order
        </Link>
        <AdminButton
          className='reporting-button'
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          <FolderIcon className='reporting-btn-icon' />
        </AdminButton>
      </div>
      <Modal
        isOpen={isModalOpen}
        title='Extraction du reporting'
        className='archive-modal'
        onClose={() => {
          setIsModalOpen(false)
        }}
      >
        <div className='order-list-date-filter order-archive-date-filter'>
          {/* Start Date & Time */}
          <div className='date-filter-container'>
            <div>
              <div className='order-list-element'>
                <label>Du</label>
                <input
                  type='date'
                  className='input-date'
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <select
                  className='input-date'
                  value={startTime}
                  onChange={(e) => {
                    setStartTime(e.target.value)
                  }}
                >
                  {timeOptions.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>

              {/* End Date & Time */}
              <div className='order-list-element'>
                <label>Au</label>
                <input
                  type='date'
                  className='input-date'
                  value={endDate}
                  onChange={handleEndDateChange}
                />
                <select
                  className='input-date'
                  value={endTime}
                  onChange={(e) => {
                    setEndTime(e.target.value)
                  }}
                >
                  {timeOptions.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <CalendarIcon height={50} width={50}></CalendarIcon>
          </div>
          {/* Export Button */}
          <div className='order-list-element'>
            <AdminButton onClick={handleExport}>Valider</AdminButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AdminPanelSidebar
