import React from 'react'
import './modal.css'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  title?: string
  className?: string
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, title, className }) => {
  if (!isOpen) return null

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div
        className={`modal-content ${className}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <button className='modal-close' onClick={onClose}>
          &times;
        </button>
        <div className='modal-title'>
          <div>{title}</div>
        </div>
        <div className='modal-body'>

        {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
